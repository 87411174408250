import React from "react";

const Footer = ({ siteTitle }) => (
  <footer className="bg-white">
    <div className="container mx-auto  px-8">
      <div className="w-full flex flex-col md:flex-row py-6">
        <div className="flex-1 mb-6">
        </div>

        <div className="flex-1">


        </div>
        <div className="flex-1">
        </div>
        <div className="flex-1">
        </div>
        <div className="flex-1">
        </div>
      </div>
    </div>
    <p class="copywright">copywright 2020</p>
  </footer>
);

export default Footer;
